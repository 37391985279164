import {catchError, Observable, of} from "rxjs";
import {Injectable} from "@angular/core";
import {RegisterStrongContractRequest} from "@scm-types/models/register-contract/RegisterStrongContractRequest";
import {GetContractEditorResponse} from "@scm-types/models/get-contract-by-id/GetContractEditorResponse";
import {ContractEditorApiErrorResponse} from "@scm-types/models/ContractEditorApiErrorResponse";
import {ServerGatewayBase} from "@logex/framework/lg-application";
import {UpdateStrongContractRequest} from "@scm-types/models/update-contract/UpdateStrongContractRequest";
import {GetStrongContractQueryRequest} from "@scm-types/models/get-contracts/GetStrongContractQueryRequest";
import {HttpErrorResponse} from "@angular/common/http";

export interface ScmEditorApiError {
    error: HttpErrorResponse;
    message: string;
}

@Injectable()
export class ScmEditorApiService extends ServerGatewayBase {
    setScmEditorApiUrl(scmEditorApiUrl: string) {
        this._setBaseUrl(scmEditorApiUrl);
    }

    getContracts(options: GetStrongContractQueryRequest = {}): Observable<any | ContractEditorApiErrorResponse> {
        const searchParams = new URLSearchParams(options as Record<keyof GetStrongContractQueryRequest, string>);
        return this._get(
            this._buildApiUrl(`strong-contracts?${searchParams}`),
        ).pipe(
            catchError((error: HttpErrorResponse): Observable<ScmEditorApiError> => of({
                error: error,
                message: error.error
            } as ScmEditorApiError)),
        );
    }

    registerContract(data: RegisterStrongContractRequest): Observable<any | ContractEditorApiErrorResponse> {
        return this._postQuery(
            this._buildApiUrl('editor/strong-contract/register-contract'),
            { body: data }
        ).pipe(
            catchError((error: HttpErrorResponse): Observable<ScmEditorApiError> => of({
                error: error,
                message: error.error
            } as ScmEditorApiError)),
        );
    }

    updateContract(data: UpdateStrongContractRequest): Observable<any | ContractEditorApiErrorResponse> {
        return this._put(
            this._buildApiUrl('editor/strong-contract/update-contract'),
            { body: data }
        ).pipe(
            catchError((error: HttpErrorResponse): Observable<ScmEditorApiError> => of({
                error: error,
                message: error.error
            } as ScmEditorApiError)),
        );
    }

    getContractById(
        uuid: string,
        addTree = false
    ): Observable<GetContractEditorResponse | any> {
        return this._get(
            this._buildApiUrl(`editor/strong-contract/${uuid}?addTree=${addTree}`),
        ).pipe(
            catchError((error: HttpErrorResponse): Observable<ScmEditorApiError> => of({
                error: error,
                message: error.error
            } as ScmEditorApiError)),
        );
    }

    /**
     * @param {string} endpointUrl relative URL to endpoint
     * @return string
     * @private
     */
    private _buildApiUrl(endpointUrl: string): string {
        return `${this._baseUrl}/api/${endpointUrl}`;
    }
}
