/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as _ from "lodash";
import {Injectable} from "@angular/core";
import {LgTranslateService} from "@logex/framework/lg-localization";
import * as Filters from "@logex/framework/lg-filterset";
import {
    FilterFactoryCreatorBase,
    IFilterFactoryEntry,
    MapFilterDefinition,
    MapFilterStore,
    SupportedFilters
} from "@shared/bases";
import {LgMonthNamePipe} from "@logex/framework/ui-core";
import {DatePipe} from "@angular/common";
import {ITextInputFilterDefinition} from "@logex/framework/lg-filterset/renderers/text-input-filter-renderer";

function formatWeekday(pipe: DatePipe, day: number): string {
    const now = new Date();
    now.setDate(now.getDate() + day - now.getDay());
    return pipe.transform(now, "EEEE") ?? "?";
}
// ---------------------------------------------------------------------------------------------
//  Implementation of the FilterFactory service
// ---------------------------------------------------------------------------------------------

@Injectable({
    providedIn: "root"
})
export class FilterFactory {
    constructor(
        private _filterSetService: Filters.LgFilterSetService,
        private _lgTranslateService: LgTranslateService,
        private _monthPipe: LgMonthNamePipe,
        private _datePipe: DatePipe
    ) {
        // empty
    }

    define(): FilterFactoryCreator {
        return new FilterFactoryCreator(
            this._filterSetService,
            this._lgTranslateService,
            this._monthPipe,
            this._datePipe
        );
    }
}

// ---------------------------------------------------------------------------------------------
type TristateSliderParameters = Pick<
    Filters.ITristateSliderFilterDefinition,
    | "nameLC"
    | "visible"
    | "containsLabel"
    | "containsLabelShort"
    | "excludesLabel"
    | "excludesLabelShort"
    | "delay"
    | "delayCallback"
>;

type Combo2Parameters<T> = Pick<
    Filters.IComboFilter2Definition<T>,
    | "source"
    | "onBecameActive"
    | "onBecameActiveLimit"
    | "onChanged"
    | "visible"
    | "name"
    | "nameLC"
>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type SelectableCombo2Parameters<T> = Pick<
    Filters.ISelectableComboFilter2Definition<T>,
    | "source"
    | "onBecameActive"
    | "onBecameActiveLimit"
    | "onChanged"
    | "visible"
    | "nameLC"
    | "onSelectionChanged"
    | "onSelectionRemoved"
>;

type CheckboxParameters = Pick<
    Filters.ICheckboxFilterDefinition,
    | "uncheckedFilters"
    | "default"
    | "previewTextLC"
    | "previewPopup"
    | "nameLC"
    | "onChanged"
    | "visible"
>;

type InputRangeParameters = Pick<
    Filters.IInputRangeFilterDefinition,
    "min" | "max" | "format" | "decimals" | "default" | "onChanged" | "visible" | "nameLC"
>;

type DateRangeParameters = Pick<
    Filters.IDateFilterDefinition,
    | "min"
    | "max"
    | "default"
    | "onChanged"
    | "visible"
    | "name"
    | "nameLC"
    | "initialMinValue"
    | "initialMaxValue"
>;

type TextInputParameters = Pick<
    ITextInputFilterDefinition,
    | "storage"
    | "label"
    | "name"
>;

// ---------------------------------------------------------------------------------------------
//  Implementation of the creator.
// ---------------------------------------------------------------------------------------------
export class FilterFactoryCreator<
    Definitions extends _.Dictionary<Filters.IFilterDefinition> = {},
    Filters extends Filters.IFilterList = {}
> extends FilterFactoryCreatorBase {
    constructor(
        _filterSetService: Filters.LgFilterSetService,
        _lgTranslateService: LgTranslateService,
        private _monthPipe: LgMonthNamePipe,
        private _datePipe: DatePipe
    ) {
        super(_filterSetService, _lgTranslateService);
    }

    // ---------------------------------------------------------------------------------------------
    addFilter<T extends SupportedFilters, N extends string>(
        id: N,
        params: T
    ): FilterFactoryCreator<
        Definitions & {
            [P in N]: MapFilterDefinition<T>;
        },
        Filters & { [P in N]: MapFilterStore<T> }
    > {
        return this._addFilter(id, params);
    }

    // ---------------------------------------------------------------------------------------------
    create(context: any): Filters.LgFilterSet<any, Filters> {
        return this._create<any, Filters>(context);
    }
    //create(context: any): Filters.LgFilterSet<Definitions, Filters> {
    //    return this._create<Definitions, Filters>(context);
    //}

    // ---------------------------------------------------------------------------------------------
    monthFilter<T extends string>(id: T, params: Combo2Parameters<number>) {
        return this._createCombo2Number(id, params, {
            showSelected: 3,
            nameLC: params.name != null ? undefined : "APP._Filters.Month",
            mapToOptions: (ids: number[]) =>
                ids.map(month => ({
                    id: month,
                    name: this._monthPipe.transform(month) ?? "?"
                })),
            allowNullValues: false
        });
    }

    // ---------------------------------------------------------------------------------------------
    weekDayFilter<T extends string>(id: T, params: Combo2Parameters<number>) {
        return this._createCombo2Number(id, params, {
            showSelected: 3,
            nameLC: params.name != null ? undefined : "APP._Filters.WeekDay",
            mapToOptions: (ids: number[]) =>
                ids
                    .map(weekDay => ({
                        id: weekDay,
                        name: formatWeekday(this._datePipe, weekDay),
                        order: weekDay
                    }))
                    .sort((one, two) => one.order - two.order),
            allowNullValues: false
        });
    }

    // ---------------------------------------------------------------------------------------------
    dayFilter<T extends string>(id: T, params: Combo2Parameters<number>) {
        return this._createCombo2Number(id, params, {
            showSelected: 5,
            nameLC: params.name != null ? undefined : "APP._Filters.Day",
            mapToOptions: (ids: number[]) =>
                ids
                    .map(day => ({
                        id: day,
                        name: day.toString(),
                        order: day
                    }))
                    .sort((one, two) => one.order - two.order),
            allowNullValues: false
        });
    }

    // ---------------------------------------------------------------------------------------------
    dateFilter<T extends string>(id: T, params: DateRangeParameters) {
        return this._createDateRange(id, params, {
            nameLC: params.name != null ? undefined : "APP._Filters.Date"
        });
    }

    textInput(id: string, params: Partial<ITextInputFilterDefinition>) {
        return this._createTextInput(id, params);
    }

    dropdown(id: string, params: IFilterFactoryEntry<Filters.IDropdownFilterDefinition<string>>) {
        return this.addFilter(id, {
            ...params,
        });
    }

    switch(id: string, params: any) {
        return this.addFilter(id, {
            ...params,
            filterType: "switch",
        });
    }

    // ---------------------------------------------------------------------------------------------
    noSightingsFilter<T extends string>(id: T, params: CheckboxParameters) {
        return this._createCheckbox(id, params, {
            nameLC: "APP._Filters.NoSightings"
        });
    }

    // ---------------------------------------------------------------------------------------------
    addMappedNumberCombo2Filter<T extends string>(
        id: T,
        params: Combo2Parameters<number> & {
            optionName: (id: number) => string;
            optionOrderById?: boolean;
        }
    ): FilterFactoryCreator<any, any> {
        return this._createCombo2Number(id, params, {
            mapToOptions: (ids: number[]) =>
                _(ids)
                    .map(optionId => {
                        const name = params.optionName(optionId);
                        return {
                            id: optionId,
                            name,
                            sort: params.optionOrderById ? optionId : name
                        };
                    })
                    .sortBy(f => f.sort)
                    .value()
        });
    }

    // ---------------------------------------------------------------------------------------------
    addMappedStringCombo2Filter<T extends string>(
        id: T,
        params: Combo2Parameters<string> & {
            optionName: (id: string) => string;
            optionOrderById?: boolean;
            sortBy?: (id: string) => number | string;
        }
    ): FilterFactoryCreator<any, any> {
        return this._createCombo2String(id, params, {
            mapToOptions: (ids: string[]) =>
                _(ids)
                    .map(optionId => {
                        const name = params.optionName(optionId);
                        return {
                            id: optionId,
                            name,
                            sort:
                                params.sortBy != null
                                    ? params.sortBy(optionId)
                                    : params.optionOrderById
                                    ? _.padStart(optionId, 15, "0")
                                    : name
                        };
                    })
                    .sortBy(f => f.sort)
                    .value()
        });
    }

    addTristateSliderFilter<T extends string>(
        id: T,
        params: TristateSliderParameters
    ): FilterFactoryCreator<any, any> {
        return this.addFilter(id, {
            filterType: "tristate-slider",
            ...params
        } as IFilterFactoryEntry<Filters.ITristateSliderFilterDefinition>);
    }

    // ---------------------------------------------------------------------------------------------
    addCheckboxFilter<T extends string>(
        id: T,
        params: CheckboxParameters
    ): FilterFactoryCreator<any, any> {
        return this._createCheckbox(id, params, {});
    }

    // ---------------------------------------------------------------------------------------------
    //  Private helpers
    // ---------------------------------------------------------------------------------------------

    private _createTextInput<T extends string>(
        id: T,
        params: Partial<ITextInputFilterDefinition>
    ) {
        return this.addFilter(id, {
            main: true,
            visible: () => true,
            ...params,
            filterType: "textInput",
        } as any);
    }

    private _createCombo2Number<T extends string>(
        id: T,
        params: Combo2Parameters<number>,
        defaults: Partial<IFilterFactoryEntry<Filters.IComboFilter2Definition<number>>>
    ) {
        return this.addFilter(id, {
            filterType: "combo2",
            main: true,
            idType: "number",
            visible: () => true,
            ...defaults,
            ...params
        } as IFilterFactoryEntry<Filters.IComboFilter2Definition<number>>);
    }

    // ---------------------------------------------------------------------------------------------
    private _createCombo2String<T extends string>(
        id: T,
        params: Combo2Parameters<string>,
        defaults: Partial<IFilterFactoryEntry<Filters.IComboFilter2Definition<string>>>
    ) {
        return this.addFilter(id, {
            filterType: "combo2",
            main: true,
            idType: "string",
            visible: () => true,
            ...defaults,
            ...params
        } as IFilterFactoryEntry<Filters.IComboFilter2Definition<string>>);
    }

    // ---------------------------------------------------------------------------------------------
    private _createDateRange<T extends string>(
        id: T,
        params: DateRangeParameters,
        defaults: Partial<DateRangeParameters>
    ) {
        return this.addFilter(id, {
            filterType: "date",
            main: true,
            visible: () => true,
            ...defaults,
            ...params
        } as IFilterFactoryEntry<Filters.IDateFilterDefinition>);
    }

    // ---------------------------------------------------------------------------------------------
    private _createInputRange<T extends string>(
        id: T,
        params: InputRangeParameters,
        defaults: Partial<InputRangeParameters>
    ) {
        return this.addFilter(id, {
            filterType: "inputRange",
            main: true,
            visible: () => true,
            ...defaults,
            ...params
        } as IFilterFactoryEntry<Filters.IInputRangeFilterDefinition>);
    }

    // ---------------------------------------------------------------------------------------------
    private _createCheckbox<T extends string>(
        id: T,
        params: CheckboxParameters,
        defaults: Partial<IFilterFactoryEntry<Filters.ICheckboxFilterDefinition>>
    ) {
        return this.addFilter(id, {
            filterType: "checkbox",
            main: true,
            visible: () => true,
            ...defaults,
            ...params
        } as IFilterFactoryEntry<Filters.ICheckboxFilterDefinition>);
    }

    // // ---------------------------------------------------------------------------------------------
    // private _createMapper<T extends number | string>(id: DefinitionKey, sortById: boolean) {
    //     return (codes: T[]) =>
    //         _(codes)
    //             .map(code => {
    //                 const name: string = this._appDefinitions.getDisplayName(id, code);
    //                 return {
    //                     id: code,
    //                     order: sortById ? this._appDefinitions.getOrderBy(id, code) : name,
    //                     name
    //                 };
    //             })
    //             .sortBy(f => f.order)
    //             .value();
    // }
}
