import { APP_INITIALIZER, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { TranslateCompiler, TranslateModule } from "@ngx-translate/core";

import { LG_ICON_LOADER_CONTENT, UiCoreModule } from "@logex/framework/ui-core";
import {LG_FILTERSET_RENDERER_FACTORIES, LgFiltersetModule} from "@logex/framework/lg-filterset";
import { LgExportsModule } from "@logex/framework/lg-exports";
import { CoreModule, LgConsoleConfiguration } from "@logex/framework/core";

import icons from "./shared/icons/app-icons-24x24.def";

import {
    LgApplicationModule,
    LgUserflowService,
    LG_APP_CONFIGURATION,
    LG_APP_INFO,
    LG_APP_SESSION,
    LG_AUTHENTICATION_SERVICE,
    LG_AUTHORIZATION_SERVICE,
    LG_NAVIGATION,
    LG_USERFLOW_SERVICE,
    LG_USER_INFO,
    LgAuthorizationServiceV2,
    OidcAuthService,
    VersionService,
    LgAcceptLanguageInterceptor,
    // LgMessageBusConnectionIdInterceptor,
    LgAuthorizationInterceptor
} from "@logex/framework/lg-application";
import {
    LgLayoutModule,
    LgLocalStorageFwUiStateService,
    LG_FW_UI_STATE_SERVICE
} from "@logex/framework/lg-layout";
import {
    LgLocalizationModule,
    LG_LOCALIZATION_SETTINGS,
    ReferenceTranslateCompiler,
    useMessageFormatLocales
} from "@logex/framework/lg-localization";

import { SharedModule } from "@shared/shared.module";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import {
    AppStartupService,
    AppLocalizationSettings,
    AppAuthenticationService,
    ApplicationPermissions,
    getNavigation,
    AppConfiguration,
    AppInfo,
    AppSession,
    AppUser
} from "@shared";
import { AuthorizedComponent } from "./authorized.component";
import { LoginComponent } from "./login.component";
import { ReplaySubject } from "rxjs";
import { ScmEditorApiService } from "./editor/api/scm-editor-api.service";

class MockedVersionService {
    protected _version = new ReplaySubject<string>();
    readonly version = this._version.asObservable();
    initialize(): void {
        setTimeout(() => {
            this._version.next("1.0.0");
        });
    }
}

@NgModule({
    declarations: [AppComponent, AuthorizedComponent, LoginComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            compiler: { provide: TranslateCompiler, useClass: ReferenceTranslateCompiler }
        }),
        CoreModule,
        UiCoreModule,
        LgLocalizationModule.forRoot({
            localizationUrlPrefix: "localization"
        }),
        LgFiltersetModule.forRoot(),
        LgApplicationModule.forRoot(),
        LgExportsModule,
        LgLayoutModule,
        SharedModule,
        HttpClientModule,
        AppRoutingModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: LgAcceptLanguageInterceptor, multi: true },
        // { provide: HTTP_INTERCEPTORS, useClass: LgMessageBusConnectionIdInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LgAuthorizationInterceptor, multi: true },
        {
            provide: VersionService,
            useClass: MockedVersionService
        },
        {
            provide: LgConsoleConfiguration,
            useFactory: () => {
                const config = new LgConsoleConfiguration();
                // config.logger( "Logex.Directives.LgPivotTableController", ConsoleLogLevel.Error );
                // config.logger( "Logex.Application.ServerGatewayBase", ConsoleLogLevel.Error );
                return config;
            }
        },
        // must contain any selectable locale
        useMessageFormatLocales(["nl", "nl-NL", "en", "en-GB", "en-150"]),
        {
            provide: LOCALE_ID,
            useValue: "en-150"
        },
        {
            provide: LG_LOCALIZATION_SETTINGS,
            useClass: AppLocalizationSettings
        },
        // we use APP_INITIALIZER mechanism to preload settings
        {
            provide: APP_INITIALIZER,
            useFactory: (startup: AppStartupService) => startup.initialize(),
            deps: [AppStartupService],
            multi: true
        },
        {
            provide: LG_APP_CONFIGURATION,
            useValue: new AppConfiguration()
        },
        {
            provide: LG_APP_INFO,
            useFactory: () => new AppInfo()
        },
        {
            provide: LG_APP_SESSION,
            useFactory: () => new AppSession()
        },
        {
            provide: LG_FW_UI_STATE_SERVICE,
            useClass: LgLocalStorageFwUiStateService
        },
        {
            provide: LG_NAVIGATION,
            useFactory: (appPermissions: ApplicationPermissions) => getNavigation(appPermissions),
            deps: [ApplicationPermissions]
        },
        {
            provide: LG_USER_INFO,
            useValue: new AppUser()
        },
        {
            provide: LG_AUTHENTICATION_SERVICE,
            useClass: AppAuthenticationService
        },
        {
            provide: LG_AUTHORIZATION_SERVICE,
            useClass: LgAuthorizationServiceV2
        },
        {
            provide: LG_USERFLOW_SERVICE,
            useExisting: LgUserflowService
        },
        {
            provide: LG_ICON_LOADER_CONTENT,
            useValue: icons,
            multi: true
        },
        OidcAuthService,
        ScmEditorApiService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
