import { HttpClient } from "@angular/common/http";
import { inject, Inject, Injectable } from "@angular/core";
import { LgConsole } from "@logex/framework/core";
import {
    IAppInfo,
    IAppSession,
    IUser,
    LG_APP_CONFIGURATION,
    LG_APP_INFO,
    LG_APP_SESSION,
    LG_AUTHENTICATION_SERVICE,
    LG_AUTHORIZATION_SERVICE,
    LG_USER_INFO,
    LgAuthorizationServiceV2,
    OidcAuthService,
    UserIdentity
} from "@logex/framework/lg-application";
import { firstValueFrom, forkJoin } from "rxjs";
import { AppAuthenticationService } from "./app-authentication.service";
import { AppConfiguration, AppStaticConfiguration } from "./app-startup.types";
import { IAppUser } from "@shared/app-user";
import {ScmEditorApiService} from "../../editor/api/scm-editor-api.service";

@Injectable({
    providedIn: "root"
})
export class AppStartupService {
    constructor(
        private _http: HttpClient,
        private _lgConsole: LgConsole,
        @Inject(OidcAuthService) private _oidcAuthService: OidcAuthService,
        @Inject(ScmEditorApiService) private _scmEditorApiService: ScmEditorApiService,
        @Inject(LG_AUTHENTICATION_SERVICE) private _authenticationService: AppAuthenticationService,
        @Inject(LG_AUTHORIZATION_SERVICE) private _authorizationService: LgAuthorizationServiceV2,
        // app LG_APP_CONFIGURATION is mostly relevant to LgBackend products, but the separation isn't finished, so we still need to provide some values there
        @Inject(LG_APP_CONFIGURATION) private _appConfiguration: AppConfiguration,
        // LG_APP_INFO is the new more-generic application config
        @Inject(LG_APP_INFO) private _appInfo: IAppInfo,
        @Inject(LG_USER_INFO) private _userInfo: IAppUser,
        @Inject(LG_APP_SESSION) private _session: IAppSession
    ) {}

    initialize(): () => Promise<void> {
        return async () => {
            this._lgConsole.debug("INIT...");

            let basePath = ""; // todo: find better way to get base path
            if (!basePath.endsWith("/")) {
                basePath += "/";
            }

            const staticConfig = await this._loadStaticConfiguration();

            this._authenticationService.configure(staticConfig, basePath);
            const authenticated = await this._authenticationService.login();
            if (!authenticated) {
                // Authentication is not finished yet - redirect required
                return;
            }

            // Our framework had the config originally injected directly into document (razor pages), so it was always there
            // Technically this is no longer the ase, but using APP_BOOTSTRAP allows us to mostly continue pretending so.
            // The code might look a bit odd though (AppModule provides empty classes, which are filled during bootstrap)
            this._fillAppConfiguration(staticConfig);
            this._fillAppInfo();
            this._fillAppSessionInfo();

            this._scmEditorApiService.setScmEditorApiUrl((staticConfig as any).services["scmEditor"]);

            this._initAuthorizationService(staticConfig);
            // We might be able to do this only once the configuration is loaded
            await this._fetchUserInfo();
        };
    }

    protected _initAuthorizationService(config: AppStaticConfiguration): void {
        this._authorizationService.initialize({
            product: config.product,
            instance: config.instance,
            url: config.services.authorization,
        });
    }

    private _fillAppInfo(): void {
        this._appInfo.productId = "demo";
        this._appInfo.versionNumber = "1.0.0"; // get version from package.json?
        this._appInfo.toolInstanceName = this._appConfiguration.instance;
        this._appInfo.environment = this._appConfiguration.environment;
    }

    private _fillAppConfiguration(config: AppStaticConfiguration): void {
        this._appConfiguration.full = config;
        this._appConfiguration.instance = config.instance;
        this._appConfiguration.environment = config.environment;
        this._appConfiguration.applicationInsightsInstrumentationKey = "";

        this._appConfiguration.testMachine = "";
        const environment = config.environment?.toLowerCase();
        if (environment !== "production") {
            if (environment === "localhost") {
                this._appConfiguration.testMachine = "dev";
            } else {
                this._appConfiguration.testMachine = `${config.instance} - ${environment}`;
            }
        }

        this._appConfiguration.applicationRoot = config.backendUrl;
        // this would normally lead to auth0 logout
        this._appConfiguration.logoutUrl = `${location.origin}/login`;

        this._appConfiguration.markReady();
    }

    private _fillAppSessionInfo(): void {
        this._session.clientId = -1; // id of default logex organization
    }

    private async _loadStaticConfiguration(): Promise<AppStaticConfiguration> {
        const config = await this._loadJsonFile<AppStaticConfiguration>("appsettings.json", false);

        // eslint-disable-next-line @typescript-eslint/dot-notation
        delete (config as any)["$schema"];
        this._lgConsole.debug("Static configuration received", config);
        return config;
    }

    // Fancy loader to support comments in json
    private async _loadJsonFile<T>(url: string, ignoreNotFound: false): Promise<T>;
    private async _loadJsonFile<T>(url: string, ignoreNotFound: true): Promise<T | null>;
    private async _loadJsonFile<T>(url: string, ignoreNotFound: boolean): Promise<T | null> {
        let configText: string;
        try {
            configText = await firstValueFrom(
                this._http.get(url, {
                    responseType: "text"
                })
            );
        } catch (e) {
            if (
                e &&
                typeof e === "object" &&
                "status" in e &&
                (e as any).status === 404 &&
                ignoreNotFound
            ) {
                return null;
            } else {
                throw e;
            }
        }

        if (configText) {
            // Strip line comments (in a very dumb way) and parse JSON
            return JSON.parse(configText.replace(/^\s*\/\/.*$/gm, ""));
        } else if (ignoreNotFound) {
            return null;
        } else {
            throw Error("Empty configuration received");
        }
    }

    private async _fetchUserInfo(): Promise<void> {
        const [profile, permissions] = await firstValueFrom(
            forkJoin([
                // Promise.resolve({
                //     accountId: 123123123,
                //     firstName: "Aliaksei",
                //     lastName: "Katarkevich"
                // }),
                this._authorizationService.getUserProfile(),
                // Promise.resolve(["admin", "read", "edit"]),
                this._authorizationService.getUserPermissions()
            ])
        );
        this._lgConsole.debug("User profile", profile);
        this._userInfo.userid = profile.id; // needed for route guards which rely on userid
        this._userInfo.name = profile.name;

        permissions.forEach(x => {
            this._userInfo.roles[x] = true;
        });
    }
}
